<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-dialog
      v-model="cropImageDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper
              :cropwidth="350"
              :cropheight="400"
              :image="currentImage"
              :key="currentImage"
              @stepper="imageCropper"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-start pt-4 px-2 px-lg-0 pl-lg-12 pl-xl-12>
      <v-flex xs12 py-4>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs7 sm3 md3 lg2 xl2>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="ECOSHOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Add a Product
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- Main Container -->
      <v-flex xs12 md12 lg7 xl7>
        <v-layout wrap justify-start>
          <!-- <v-flex xs12 sm12 md12 lg12 xl12 text-left>
            <span class="ecoshopinputlabel">Title </span>
            <v-text-field
              v-model="product.title"
              color="#30B868"
              hide-selected
              outlined
              placeholder="Product Title"
              dense
              hide-details
            ></v-text-field>
          </v-flex> -->
          <v-flex xs12 sm12 md12 lg12 xl12 text-left>
            <span class="ecoshopinputlabel"> Select Category type </span>
            <v-select
              outlined
              small-chips
              deletable-chips
              dense
              v-model="product.category"
              color="#30B868"
              placeholder="Category"
              :items="categoryArray"
              item-text="name"
              item-value="_id"
              item-color="#30B868"
              hide-details
              v-on:input="subSize(product.category)"
            >
            </v-select>
          </v-flex>
          <v-flex xs12 sm12 text-left pt-2>
            <span class="ecoshopinputlabel"> Product Unique Id </span>
            <v-text-field
              v-model="product.productcode"
              color="#30B868"
              hide-selected
              outlined
              placeholder="Product Unique Id"
              dense
              hide-details
            >
              <template v-slot:append>
                <v-btn text color="#30B868" @click="assignCode()">
                  <span
                    style="
                      font-family: opensanssemibold;
                      text-transform: none;
                      color: #30b868;
                    "
                    >Assign Code</span
                  >
                </v-btn>
              </template></v-text-field
            >
          </v-flex>
          <!-- <v-flex xs12 sm8 pl-sm-2 text-left>
            <span class="ecoshopinputlabel"> Pincode </span>
            <v-select
              outlined
              small-chips
              deletable-chips
              dense
              multiple
              v-model="product.pincodeGroup"
              color="#30B868"
              placeholder="Regions"
              :items="pincodeArray"
              item-text="name"
              item-value="_id"
              item-color="#30B868"
              hide-details
            >
            </v-select> -->
          <!-- </v-flex> -->
          <v-flex xs12 text-left pt-2>
            <!-- @input="checkNameAvailability" -->
            <span class="ecoshopinputlabel"> Name of the Product </span>
            <v-text-field
              v-model="product.productname"
              color="#30B868"
              hide-selected
              outlined
              placeholder="Name of the product"
              dense
              hide-details
            ></v-text-field>
            <!-- <span
              style="color: red; font-size: 12px; font-family: poppinsregular"
              >{{ NameMsg }}</span
            > -->
          </v-flex>
          <!-- <v-flex xs12 text-left pt-2>
            <span class="ecoshopinputlabel"> Caption of the Product </span>
            <v-textarea
              rows="2"
              v-model="product.caption"
              color="#30B868"
              hide-selected
              hide-details
              outlined
              placeholder="Enter a few words about the product"
              dense
              counter
              maxlength="100"
            ></v-textarea>
          </v-flex> -->
          <v-flex xs12 text-left pt-2>
            <span class="ecoshopinputlabel"> Description of the Product </span>
            <v-textarea
              rows="3"
              v-model="product.description"
              color="#30B868"
              hide-selected
              hide-details
              outlined
              placeholder="Describe the product"
              dense
              counter
            ></v-textarea>
          </v-flex>
          <v-flex xs12 sm6 text-left pt-2>
            <span class="ecoshopinputlabel"> GST Percentage </span>
            <v-text-field
              v-model="product.gstPercentage"
              color="#30B868"
              hide-selected
              outlined
              placeholder="GST Percentage"
              dense
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left pt-2 pl-2>
            <span class="ecoshopinputlabel">HSN Code </span>
            <v-text-field
              v-model="product.hsnCode"
              color="#30B868"
              hide-selected
              outlined
              placeholder="HSN Code"
              dense
              hide-details
            ></v-text-field>
          </v-flex>
          <!-- <v-flex xs12 text-left pt-2>
            <span class="ecoshopinputlabel"> Delivery Charge </span>
            <v-text-field
              v-model="product.deliveryCharge"
              color="#30B868"
              hide-selected
              outlined
              placeholder="Delivery Charge"
              dense
              hide-details
            ></v-text-field>
          </v-flex> -->
          <!-- <v-flex xs12 text-left pt-2>
            <span class="ecoshopinputlabel">Add Description</span>
            <v-combobox
              placeholder="Description Tags"
              v-model="product.tags"
              outlined
              :items="tagArray"
              color="#30B868"
              multiple
              dense
              hide-details
              small-chips
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <span class="pa-1">
                  <v-chip v-bind="attrs" :input-value="selected" small>
                    <span
                      class="pr-2"
                      style="font-family: opensansregular; font-size: 13px"
                    >
                      {{ item }} </span
                    >&nbsp;
                    <v-icon small @click="parent.selectItem(item)">
                      mdi-close-circle
                    </v-icon>
                  </v-chip>
                </span>
              </template>
              <template v-slot:append>
                <span></span>
              </template>
            </v-combobox>
          </v-flex>
          <v-flex xs12 text-left pt-2>
            <span class="ecoshopinputlabel">Material Used</span>
            <v-combobox
              placeholder="Tags"
              v-model="product.materialused"
              outlined
              color="#30B868"
              multiple
              :items="materialArray"
              dense
              hide-details
              small-chips
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <span class="pa-1">
                  <v-chip v-bind="attrs" :input-value="selected" small>
                    <span
                      class="pr-2"
                      style="font-family: opensansregular; font-size: 13px"
                    >
                      {{ item }} </span
                    >&nbsp;
                    <v-icon small @click="parent.selectItem(item)">
                      mdi-close-circle
                    </v-icon>
                  </v-chip>
                </span>
              </template>
              <template v-slot:append>
                <span></span>
              </template>
            </v-combobox>
          </v-flex> -->
        </v-layout>
      </v-flex>
      <!-- Image Container -->
      <v-flex xs12 md12 lg4 xl3 pl-sm-4 pt-2 pb-4>
        <v-layout wrap justify-center>
          <v-flex pt-4 xs12 text-left>
            <v-layout wrap justify-center>
              <v-flex xs8 sm6 md6 lg8>
                <v-card flat tile outlined height="300px">
                  <v-layout wrap justify-center fill-height>
                    <v-flex md12 lg12 align-self-center>
                      <v-img
                        v-if="imageArray[0]"
                        :src="imageArray[0]"
                        height="298px"
                        contain
                      >
                        <v-layout wrap>
                          <v-flex text-right pa-2>
                            <v-avatar color="#FF3434" size="20">
                              <v-icon
                                color="#FFF"
                                small
                                @click="removeImageArray(0)"
                              >
                                mdi-close
                              </v-icon>
                            </v-avatar>
                          </v-flex>
                        </v-layout>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs4 sm3 md3 lg4>
                <v-layout pt-0 wrap justify-center>
                  <v-flex xs12 v-for="(item, i) in imageArray" :key="i" pa-1>
                    <v-img v-if="i > 0" :src="item" height="85px" contain>
                      <v-layout wrap>
                        <v-flex text-right pa-0>
                          <v-avatar color="#FF3434" size="15">
                            <v-icon
                              color="#FFF"
                              small
                              @click="removeImageArray(i)"
                            >
                              mdi-close
                            </v-icon>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm9 md9 lg12 pt-4 text-left>
            <v-btn
              width="180px"
              small
              tile
              depressed
              :ripple="false"
              class="text-capitalize"
              color="#EFEFEF"
              @click="$refs.files.click()"
            >
              <v-layout wrap justify-center>
                <v-flex xs8 text-right>
                  <span style="font-size: 12px; font-family: opensanssemibold">
                    Upload Image
                  </span>
                </v-flex>
                <v-flex xs4 text-right>
                  <v-img
                    src="./../../../assets/icons/file-upload.svg"
                    height="15px"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>
            </v-btn>
            <input
              v-show="false"
              accept="image/*"
              id="file"
              ref="files"
              multiple="multiple"
              type="file"
              @change="uploadImages"
            />
          </v-flex>
          <v-flex xs12>
            <span style="color: red; font-family: opensansbold; font-size: 14px"
              >Note : Please upload 350 (Width) * 400 (Height) Pixel
              Images</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- Product Stock -->
      <v-flex xs12 md12 lg10 xl7 pt-2>
        <v-layout wrap justify-start>
          <v-flex xs12 text-left>
            <span class="ecoshopinputlabel">Product Stock </span>
          </v-flex>
          <v-flex xs12>
            <StockAdder
              v-bind:storage="subSizeArray"
              :key="subSizeArray[0]"
              @stepper="winStepper"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12 md12 lg7 xl7 pt-3>
        <v-layout wrap justify-start>
          <v-flex xs12 md12 lg7 text-left>
            <v-radio-group v-model="radios" mandatory class="radC" dense>
              <v-radio
                color="#30B868"
                label="Total Price"
                value="totalPrice"
              ></v-radio>
              <v-radio color="#30B868" label="Price " value="price"></v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
      </v-flex> -->
      <v-flex xs12 md12 lg7 xl7>
        <v-layout pt-2 wrap>
          <v-flex sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel"> Total Stock </span>
            <v-text-field
              disabled
              type="number"
              placeholder="Stock"
              v-model="product.stockquantity"
              outlined
              color="#30B868"
              hide-details
              dense
            ></v-text-field>
          </v-flex>
          <!-- <v-flex pl-sm-2 sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel">Price </span>
            <v-text-field
              placeholder="Price"
              style="max-height: 46px !important"
              type="number"
              v-model="product.productcost"
              outlined
              color="#30B868"
              hide-details
              dense
            ></v-text-field> -->
          <!-- :disabled="radios == 'price' ? false : true" -->
          <!-- <v-layout wrap justify-end v-if="radios == 'price'">
              <v-flex style="cursor:pointer" xs12 text-right @click="calculate()">
                <span class="ecoshopinputlabel" style="color: green"
                  >Calculate</span
                >
              </v-flex>
            </v-layout> -->
          <!-- </v-flex> -->
          <!-- <v-flex pl-sm-2 sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel">Markup Price</span>
            <v-text-field
              placeholder="Markup Price"
              type="number"
              v-model="product.profitmargin"
              outlined
              color="#30B868"
              hide-details
              dense
            ></v-text-field>
          </v-flex> -->

          <!-- <v-flex pl-sm-2 sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel">Total Price</span>
            <v-text-field
            
              :disabled="radios == 'totalPrice' ? false : true"
              placeholder="Total Price"
              type="number"
              v-model="product.price"
              outlined
              color="#30B868"
              dense
              hide-details
            ></v-text-field>
          </v-flex> -->
          <!-- <v-flex pl-sm-2 sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel">Total Price</span>
            <v-text-field
              :disabled="radios == 'totalPrice' ? false : true"
              placeholder="Total Price"
              type="number"
              v-model="priceDetails.totalAmountToCustomer"
              outlined
              color="#30B868"
              dense
              hide-details
            ></v-text-field>
            <v-layout wrap justify-end v-if="radios == 'totalPrice'">
              <v-flex style="cursor:pointer" xs12 text-right @click="calculateTot()">
                <span class="ecoshopinputlabel" style="color: green"
                  >Calculate</span
                >
              </v-flex>
            </v-layout>
          </v-flex> -->
        </v-layout>
        <v-layout wrap justify-center pt-2> </v-layout>
        <!-- <v-layout wrap py-2>
          <v-flex text-left xs12 lg12>
            <PriceDistro
              v-bind:productcost="product.productcost"
              v-bind:totalprice="product.price"
              :priceDetails="priceDetails"
              v-bind:subcategoryData="singleSubcatData"
              :key="product.price"
              @stepper="priceChanger"
            />
          </v-flex>
        </v-layout> -->
        <!-- Text-input -->
      </v-flex>
      <v-flex md12 lg7 pb-3 pt-4 text-right>
        <v-btn
          width="250px"
          tile
          dark
          color="#30B868"
          depressed
          class="text-capitalize"
          :ripple="false"
          @click="addProduct()"
        >
          Apply
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import StockAdder from "./stockAdder";
// import PriceDistro from "./priceDistro";
import ImageCropper from "./../../Common/imageCropper";
import axios from "axios";
export default {
  components: {
    StockAdder,
    // PriceDistro,
    ImageCropper,
  },
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      imageArray: [],
      Images: new FormData(),
      formData: new FormData(),
      selectedFiles: null,
      image: null,
      pid: "",
      // pincodeArray: [],
      tagArray: [],
      materialArray: [],
      brandArray: [],
      subSizeArray: [],
      productStock: {},
      radios: null,
      product: {
        title: "",
        productcode: null,
        // subcategory: null,
        category: [],
        gstPercentage: "",
        hsnCode: "",
        // pincodeGroup: [],
        // brand: null,
        productname: null,
        urlname: null,
        caption: null,
        description: null,
        stockquantity: null,
        productcost: null,
        deliveryCharge: "",
        // styl: [],
        // profitmargin: null,
        price: null,
        newprice: "",
        instock: false,
        unit: "Count",
        // tags: [],
        // materialused: [],
        size: [],
        // productTag: null,
      },
      productTags: [],
      priceStatus: false,
      price: {
        productCost: null,
        // profitMargin: null,
        deliveryCharge: null,
        gst: null,
        finalCustomerCharge: null,
        styloopCommision: null,
        styloopGST: null,
        tcs: null,
        paymentGateewayCharge: null,
        amountToSeller: null,
        gstPercentage: null,
        styloopCommisionPercentage: null,
        styloopGstPercentage: null,
        tcsPercentage: null,
        paymentGatewayChargePercentage: null,
      },

      rules: {
        required: (value) => !!value || "Required.",
      },
      cropImageDialog: false,
      currentImage: null,
      singleSubcatData: {},
      NameMsg: null,
      newprice: null,
      priceDetails: [],
      categoryArray: [],
    };
  },
  watch: {
    radios() {
      if (this.radios == "price") {
        this.product.price = null;
      }
      if (this.radios == "totalPrice") {
        this.newprice = null;
      }
    },
    newprice() {
      // if(this.radios=="price"){
      var taxpercentage = this.singleSubcatData.HSN.gstPercentage;
      if (
        this.newprice > this.singleSubcatData.HSN.gstLevel2MinumumPrice &&
        this.singleSubcatData.HSN.gstLevel2MinumumPrice > 0
      ) {
        taxpercentage = this.singleSubcatData.HSN.gstLevel2Percentage;
      }
      var taxamount = (this.newprice * taxpercentage) / (100 + taxpercentage);
      this.product.productcost = this.newprice - taxamount;
      // }
      // else{
      //   this.newprice = null;
      // }
    },
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    appType() {
      return this.$store.state.userType;
    },
  },
  beforeMount() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/category/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    assignCode(){
      axios({
        url: "/product/generateCode",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.product.productcode = response.data.code;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    calculate() {
      this.appLoading = true;
      axios({
        url: "/calculate/tax",

        method: "POST",
        data: {
          amount: this.product.productcost,
          categoryId: this.product.subcategory,
          type: "Price",
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.priceDetails = response.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    calculateTot() {
      this.appLoading = true;
      axios({
        url: "/calculate/tax",
        method: "POST",
        data: {
          amount: this.priceDetails.totalAmountToCustomer,
          categoryId: this.product.subcategory,
          type: "TotalPrice",
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.priceDetails = response.data;
          this.product.productcost = this.priceDetails.amount;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    priceChanger(window_data) {
      if (window_data.price) {
        this.product.price = window_data.price;
        if (this.newprice !== window_data.price)
          this.newprice = window_data.price;
      }
    },
    winStepper(window_data) {
      this.productStock = window_data.productStock;
      this.product.size = this.productStock;
      this.product.stockquantity = 0;
      for (var i = 0; i < this.productStock.length; i++) {
        for (var j = 0; j < this.productStock[i].stock.length; j++) {
          this.product.stockquantity =
            this.product.stockquantity + this.productStock[i].stock[j].stock;
        }
      }
    },
    subSize(id) {
      for (var i = 0; i < this.categoryArray.length; i++) {
        if (id == this.categoryArray[i]._id) {
          this.subSizeArray = this.categoryArray[i].size;
          this.singleSubcatData = this.categoryArray[i];
        }
      }
    },
    // checkNameAvailability() {
    //   // this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/product/name/available",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params: {
    //       name: this.product.productname,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         // this.NameMsg = response.data.msg;
    //         // this.appLoading = false;
    //       } else {
    //         this.NameMsg = response.data.msg;
    //         // this.appLoading = false;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    uploadImages(event) {
      if (this.imageArray.length < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages(image) {
      this.selectedFiles = image;
      this.Images.append("image", this.selectedFiles);
      this.formData.append("image", this.selectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
    },
    removeImageArray(i) {
      var values = this.formData.getAll("photos");
      values.splice(i, 1);
      this.formData.set("photos", values);
      this.imageArray.splice(i, 1);
    },
    addProduct() {
      this.appLoading = true;
      // this.product.profitmargin = parseFloat(this.product.profitmargin);
      this.product.urlname = this.product.productname.split(/[\s]+/).join("-");
      this.product.productcost = parseFloat(this.product.productcost);
      // this.product.price = parseFloat(this.product.price);
      this.product.price = parseFloat(this.priceDetails.totalAmountToCustomer);
      this.product.stockquantity = Number(this.product.stockquantity);
      if (this.product.stockquantity > 0) this.product.instock = true;

      var urlcall = "/product/Add";
      axios({
        method: "POST",
        url: urlcall,
        data: this.product,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.imageArray.length > 0) {
              this.uploadAllImages(response.data.id);
            } else {
              this.$router.push("/Product/Added/" + response.data.id);
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadAllImages(item) {
      this.appLoading = true;
      this.formData.append("productid", item);
      axios({
        method: "POST",
        url: "/Product/UploadImageMultiple",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.$router.push("/Product/Added/" + item);
          } else {
            this.msg = "Can't Upload Images";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.v-input__slot {
  min-height: 36px;
}
.v-select > .dropdown-menu {
  text-align: right;
}
.radC >>> label {
  font-family: opensansbold;
  color: #000000;
  font-size: 13px;
  padding-left: 10px;
}
</style>
